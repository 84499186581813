import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Drawer,
  FormInput,
  FormSelect,
} from "@stonelog/stonelog-design-system";
import {
  brazillianStates,
  stateByUF,
  toCapitalizeFirstLetterOfEachWord,
} from "../../../../../../utils/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { schema } from "./schema";
import {
  capitalizeFirstLetter,
  capitalizeFirstWord,
} from "../../../../../../utils/formatter";
import {
  clearZipCodeAddressInformation,
  getZipCodeAddressInformation,
  zipCodeAddressInformationSelector,
} from "../../../../../../features/senninha/zipCodeAddressInformationSlice";
import {
  postProviderInformation,
  providerInformationsSelector,
  setResetPostProviderInformationStatus,
} from "../../../../../../features/senninha/GestaoEquipe/providerInformationsSlice";
import { hubsSelector } from "../../../../../../features/senninha/hubsSlice";
import { employeesInfosLogisticSelector } from "../../../../../../features/senninha/employeesInfosLogistic";
import { useDispatch, useSelector } from "react-redux";
import warning from "@assets/icons/Warning.svg";

import * as S from "./styles";
import { set } from "date-fns";

const InfosProviderEditing = ({
  openProviderEditDrawer,
  setOpenProviderEditDrawer,
}) => {
  const [openCloseModal, setOpenCloseModal] = useState(false);

  const dispatch = useDispatch();

  const {
    zipCodeAddressInformationData,
    zipCodeAddressInformationStatus,
  } = useSelector(zipCodeAddressInformationSelector);

  const {
    getProviderInformationData,
    postProviderInformationStatus,
  } = useSelector(providerInformationsSelector);

  const { selectedHubInfos } = useSelector(hubsSelector);
  console.log("selectedHubInfos", selectedHubInfos);

  const { employeesInfosLogisticData } = useSelector(
    employeesInfosLogisticSelector
  );

  const {
    control,
    handleSubmit,
    reset,
    formState,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {},
  });

  useEffect(() => {
    if (getProviderInformationData) {
      reset({
        razaoSocial:
          capitalizeFirstLetter(getProviderInformationData.razao_social) || "",
        nomeFantasia:
          capitalizeFirstWord(getProviderInformationData.nome_fantasia) || "",
        cpf: getProviderInformationData.documento || "",
        email: getProviderInformationData.email || "",
        pessoaResponsavel: getProviderInformationData.pessoa_responsavel || "",
        contato: getProviderInformationData.contato || "",
        cep: getProviderInformationData.cep || "",
        endereco: getProviderInformationData.endereco || "",
        numeroEndereco: getProviderInformationData.numero_endereco || "",
        complemento: getProviderInformationData.complemento || "",
        bairro: getProviderInformationData.bairro || "",
        uf: getProviderInformationData.uf || "",
        cidade: getProviderInformationData.cidade || "",
      });
    }
  }, [getProviderInformationData, reset]);

  useEffect(() => {
    if (
      zipCodeAddressInformationData?.length !== 0 &&
      zipCodeAddressInformationStatus === "fulfilled"
    ) {
      const { logradouro, bairro, cidade, uf } = zipCodeAddressInformationData;
      setValue("endereco", logradouro || "");
      setValue("bairro", bairro || "");
      setValue("cidade", cidade || "");
      setValue("uf", uf || "");
      setValue("numero_endereco", "");
      setValue("complemento", "");
    }
  }, [zipCodeAddressInformationStatus]);

  useEffect(() => {
    if (postProviderInformationStatus === "fulfilled") {
      reset();
      setResetPostProviderInformationStatus();
    }
  }, [postProviderInformationStatus]);

  const handleForm = (data) => {
    const payload = {
      email: data.email,
      pessoa_responsavel: data.pessoaResponsavel,
      contato: data.contato,
      cep: data.cep,
      endereco: data.endereco,
      numero_endereco: data.numeroEndereco,
      complemento: data?.complemento || undefined,
      bairro: data.bairro,
      uf: data.uf,
      cidade: data.cidade,
      polo: selectedHubInfos.children,
      id_polo: getProviderInformationData?.id_polo,
    };
    console.log(payload);
    dispatch(postProviderInformation(payload));
  };

  const onChangeZipCode = (e) => {
    let cep = e.target.value;
    setValue("cep", cep);
    if (cep.length === 8) {
      dispatch(getZipCodeAddressInformation(cep));
    } else if (cep[5] === "-" && cep.length === 9) {
      dispatch(getZipCodeAddressInformation(cep.replace("-", "")));
    }
  };

  const emailsOptions = (employeesInfosLogisticData) =>
    employeesInfosLogisticData?.map((item) => ({
      value: item.email,
      label: item.email,
    }));

  return (
    <>
      {() => handleShowNotification("morango")}
      <Dialog.Root
        open={openCloseModal}
        onCancel={() => setOpenCloseModal(false)}
      >
        <Dialog.Header title="Atenção" />

        <Dialog.ContentHeader
          ilustration={<img src={warning} />}
          description="Ao cancelar, todas as informações inseridas irão ser perdidas. Tem certeza de que deseja continuar?"
        />

        <Dialog.Footer>
          <Button onClick={() => setOpenCloseModal(false)}>Voltar</Button>
          <Button
            type="negative"
            onClick={() => {
              setOpenCloseModal(false);
              setOpenProviderEditDrawer();
              reset();
              dispatch(clearZipCodeAddressInformation());
            }}
          >
            Confirmar cancelamento
          </Button>
        </Dialog.Footer>
      </Dialog.Root>

      <Drawer.Root
        open={openProviderEditDrawer}
        onClose={() => setOpenProviderEditDrawer(false)}
        getContainer={false}
        title={<Drawer.Header title="Editar polo" date={new Date()} />}
      >
        <Drawer.Body>
          <form onSubmit={handleSubmit(handleForm)} id="provider-form">
            <S.AllDrawerProviderEditing>
              <FormInput
                label="Razão social"
                size="small"
                disabled
                control={control}
                name="razaoSocial"
              />
              <FormInput
                label="Nome fantasia"
                size="small"
                disabled
                control={control}
                name="nomeFantasia"
              />
              <FormInput
                label="CPF/CNPJ"
                size="small"
                disabled
                control={control}
                name="cpf"
              />
              <FormSelect
                label="E-mail"
                size="small"
                placeholder="Digite ou selecione um e-mail"
                allowClear
                control={control}
                name="email"
                showSearch
                className="ginga-team-select"
                options={emailsOptions(employeesInfosLogisticData)}
                onChange={(value) => {
                  if (!value) {
                    setValue("email", null);
                    setValue("pessoaResponsavel", null);
                  } else {
                    setValue("email", value);

                    if (value.includes("@")) {
                      const namePart = value.split("@")[0];
                      const nameFormatted = namePart
                        .split(".")
                        .map(toCapitalizeFirstLetterOfEachWord)
                        .join(" ");

                      setValue("pessoaResponsavel", nameFormatted);
                    }
                  }
                }}
              />
              <FormInput
                label="Pessoa responsável"
                size="small"
                placeholder="Nome e sobrenome"
                allowClear
                control={control}
                name={"pessoaResponsavel"}
              />
              <FormInput
                label="Contato"
                size="small"
                placeholder="(DDD) 000000000"
                allowClear
                control={control}
                name="contato"
              />
              <FormInput
                label="CEP"
                size="small"
                placeholder="00000000"
                allowClear
                control={control}
                name="cep"
                onChange={(e) => onChangeZipCode(e)}
                loading={zipCodeAddressInformationStatus === "pending"}
              />
              <p style={{ marginTop: "-7.9232px" }}>
                Após o preenchimento do CEP, as informações do endereço serão
                preenchidas automaticamente.
              </p>
              <FormInput
                label="Endereço"
                size="small"
                placeholder="Digite aqui"
                control={control}
                name="endereco"
                allowClear={zipCodeAddressInformationStatus !== "pending"}
                loading={zipCodeAddressInformationStatus === "pending"}
              />
              <S.DrawerGridInputs>
                <FormInput
                  label="Número"
                  size="small"
                  placeholder="0000"
                  allowClear
                  control={control}
                  name="numeroEndereco"
                />
                <FormInput
                  label="Complemento"
                  size="small"
                  placeholder="Opcional"
                  allowClear
                  control={control}
                  name="complemento"
                />
              </S.DrawerGridInputs>
              <FormInput
                label="Bairro"
                size="small"
                placeholder="Digite aqui"
                control={control}
                name="bairro"
                allowClear={zipCodeAddressInformationStatus !== "pending"}
                loading={zipCodeAddressInformationStatus === "pending"}
              />
              <S.DrawerGridInputsSecond>
                <FormInput
                  label="Cidade"
                  size="small"
                  placeholder="Digite aqui"
                  control={control}
                  name="cidade"
                  allowClear={zipCodeAddressInformationStatus !== "pending"}
                  loading={zipCodeAddressInformationStatus === "pending"}
                />
                <FormSelect
                  label="Estado"
                  size="small"
                  placeholder="UF"
                  options={brazillianStates}
                  control={control}
                  name="uf"
                  loading={zipCodeAddressInformationStatus === "pending"}
                  onChange={(selectedState) => {
                    const cities = stateByUF[selectedState];
                    if (cities && cities?.length > 0) {
                      setValue("uf", selectedState);
                      setValue("cidade", cities[0].estado);
                    }
                  }}
                />
              </S.DrawerGridInputsSecond>
            </S.AllDrawerProviderEditing>
          </form>
        </Drawer.Body>
        <Drawer.Footer>
          <Button
            width="100%"
            type="primary"
            size="medium"
            htmlType="submit"
            form="provider-form"
            disabled={!formState.isValid}
            loading={postProviderInformationStatus === "pending"}
          >
            Salvar alterações
          </Button>
          <Button
            width="100%"
            size="medium"
            onClick={() => {
              setOpenCloseModal(true);
            }}
          >
            Cancelar
          </Button>
        </Drawer.Footer>
      </Drawer.Root>
    </>
  );
};

export default InfosProviderEditing;
