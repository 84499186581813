import styled from "styled-components";


export const AllDrawerProviderEditing = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .ant-input-affix-wrapper  {
        padding: 0px 9px 0px 12px !important;
    }

    p{
        color: var(--Content-Neutral, #767F8D);
        font-family: "Sharon Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: -0.12px;
    }
`;

export const DrawerGridInputs = styled.div`
    display: grid;
    align-items: start;
    grid-gap: 12px;
    grid-template-columns: 3fr 5fr;
`;

export const DrawerGridInputsSecond = styled.div`
    display: grid;
    align-items: start;
    grid-gap: 12px;
    grid-template-columns: 3fr 1fr;
`;
