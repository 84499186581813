import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = "senninhaEmployeesInfosLogistic";
const GET_EMPLOYEES_INFOS_LOGISTIC = `${REDUCER_NAME}/getEmployeesInfosLogistic`;

const initialState = {
    employeesInfosLogisticData: null,
    statusEmployeesInfosLogisticData: 'idle',
    errorEmployeesInfosLogisticData: null,
};

export const getEmployeesInfosLogistic = createAsyncThunk(GET_EMPLOYEES_INFOS_LOGISTIC, async (_, { rejectWithValue }) => {
    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/empregados_logistica`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const employeesInfosLogisticSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeesInfosLogistic.pending, (state) => {
                state.statusEmployeesInfosLogisticData = "pending";
                state.errorEmployeesInfosLogisticData = null;
            })
            .addCase(getEmployeesInfosLogistic.fulfilled, (state, action) => {
                state.employeesInfosLogisticData = action.payload;
                state.statusEmployeesInfosLogisticData = "fulfilled";
            })
            .addCase(getEmployeesInfosLogistic.rejected, (state, action) => {
                state.employeesInfosLogisticData = null;
                state.errorEmployeesInfosLogisticData = action.payload;
                state.statusEmployeesInfosLogisticData = "rejected";
            });
    },
});

export const { } = employeesInfosLogisticSlice.actions

export const employeesInfosLogisticSelector = (state) => state.employeesInfosLogisticSlice;

export default employeesInfosLogisticSlice.reducer;
