import { createSlice } from "@reduxjs/toolkit";

const REDUCER_NAME = "trackingRoutes";

const initialState = {
  routesPolylines: [],
};

export const trackingRoutesSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    addTrackingRoute: (state, action) => {
      const hasRoute = state.routesPolylines.find(
        (item) => item.trackingCode === action.payload.trackingCode
      );
      if (!hasRoute)
        state.routesPolylines = [...state.routesPolylines, action.payload];
    },
    updateTrackingRoutes: (state, action) => {
      state.routesPolylines = state.routesPolylines.filter(
        (item) => action.payload.includes(item.trackingCode)
      );
    },
    deleteTrackingRoutes: (state, action) => {
      state.routesPolylines = state.routesPolylines.filter(
        (item) => !action.payload.includes(item.trackingCode)
      );
    },
    resetTrackingRoutesSlice: (state) => {
      state = initialState;
    },
  },
});

export const {
  addTrackingRoute,
  updateTrackingRoutes,
  deleteTrackingRoute,
  resetTrackingRoutesSlice,
} = trackingRoutesSlice.actions;

export const trackingRoutesSelector = (state) => state.trackingRoutesSlice;

export default trackingRoutesSlice.reducer;
