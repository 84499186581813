import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { downloadBase64PDF } from '../../utils/common'

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'ticketPreDelivery';
const GET_TICKETS = 'ticketPreDelivery/getTickets';
const GET_TICKETS_UNINSTALL = 'ticketPreDelivery/getTicketsUninstall';

const initialState = {
  status: 'idle',
  statusUninstall: 'idle',
  error: null,
  errorUninstall: null,
};

export const getTickets = createAsyncThunk(GET_TICKETS, async (data, { rejectWithValue }) => {
  try {
    const res = await LogisticService.postWithAuth(`${BASE_URL}/any/gerar_etiqueta`, data);

    if (res?.data?.fileBase) downloadBase64PDF(res?.data?.fileBase)
    return
  } catch (err) {
    return rejectWithValue(err.message || err.response.data || err.response || 'Houve um erro no servidor, contate o time de sistemas.');
  }
});

export const getTicketsUninstall = createAsyncThunk(GET_TICKETS_UNINSTALL, async (data, { rejectWithValue }) => {
  try {
    const res = await LogisticService.postWithAuth(
      `${BASE_URL}/files/generate_os_pdf`, 
      { numero_oss: data }
    );

    if (res?.data?.fileBase) downloadBase64PDF(res?.data?.fileBase)
    return;
  } catch (err) {
    console.log('err', err)
    return rejectWithValue(err.message || err.response.data || err.response || 'Houve um erro no servidor, contate o time de sistemas.');
  }
});

export const ticketSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetTicketSlice: (state) => {
      state.status = 'idle'
      state.statusUninstall = 'idle'
      state.error = null
      state.errorUninstall = null
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getTickets.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getTickets.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'rejected';
      })
      
      .addCase(getTicketsUninstall.pending, (state) => {
        state.statusUninstall = 'pending';
        state.errorUninstall = null;
      })
      .addCase(getTicketsUninstall.fulfilled, (state) => {
        state.statusUninstall = 'fulfilled';
      })
      .addCase(getTicketsUninstall.rejected, (state, action) => {
        state.errorUninstall = action.payload;
        state.statusUninstall = 'rejected';
      });
  },
});

export const {
  resetTicketSlice
} = ticketSlice.actions

export const ticketsSelector = (state) => state.ticketSlice;

export default ticketSlice.reducer;
