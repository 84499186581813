import EditableTable from "@components/EditableTable";
import Box from "@components/Box";
import LogisticService from "@LogisticService";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
// import { useAuthContext } from '../../../../../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

import * as S from "./../styles";
import { message } from "antd";

const Comerciais = ({ polo }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [polosComerciais, setPolosComerciais] = useState([]);
  const [atualizaEditabletable, setAtualizaEditabletable] = useState(false);
  const [downloadLabels, setDownloadLabels] = useState([
    "Email Comercial",
    "Polo Comercial",
    "Usuário",
  ]);
  const auth = useAuthContext();
  const userName = auth.getUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (polo !== "" && polo !== undefined) {
          const response = await LogisticService.getComerciais(polo);
          if (response !== undefined && response.data !== null) {
            setData(response.data);
            setLoaded(false);
  
            const polos = await LogisticService.getPolosComerciais();
            if (polos !== undefined && polos.data !== null) {
              let polos_comerciais = [];
              polos.data.polos.map((polo) => {
                polos_comerciais.push({ id: polo, name: polo });
              });
              setPolosComerciais(polos_comerciais);
            }
          }
        }
      } catch (error) {
        message.error('Erro ao carregar dados.');
      } finally {
        setLoaded(true);
      }
    };
  
    fetchData();
  }, [atualizaEditabletable]);
  
  const createRequest = async (item) => {
    var invalidEntries = 0;
    function filterByEmail(obj) {
      if (
        "email_comercial" in obj &&
        obj.email_comercial == item.email_comercial
      ) {
        return true;
      } else {
        invalidEntries++;
        return false;
      }
    }
    var testing = data.filter(filterByEmail);
    if (testing.length > 0)
      return {
        success: false,
        message: "Usuário já cadastrado, edite na tabela abaixo.",
      };
    const req = { ...item, id_polo_logistico: polo };
    const response = await LogisticService.postComercial(req);
    setAtualizaEditabletable(!atualizaEditabletable);
    if (response.data)
      if (response.data.sucesso)
        return { success: true, message: response.data.sucesso };
      else return { success: false, message: response.data.erro };
  };

  const editRequest = async (data) => {
    const req = { ...data, id_polo_logistico: polo };
    const response = await LogisticService.postComercial(req);
    setAtualizaEditabletable(!atualizaEditabletable);
    if (response.data)
      if (response.data.sucesso)
        return { success: true, message: response.data.sucesso };
      else
        return {
          success: false,
          message: response.data.erro.errors[0].message,
        };
  };

  const deleteRequest = async (data) => {
    const req = { ...data, id_polo_logistico: polo, ativo: false };
    const response = await LogisticService.postComercial(req);
    setAtualizaEditabletable(!atualizaEditabletable);
    if (response.data)
      if (response.data.sucesso)
        return { success: true, message: response.data.sucesso };
      else
        return {
          success: false,
          message: response.data.erro.errors[0].message,
        };
  };

  const colunas = [
    {
      title: "Email Comercial",
      dataIndex: "email_comercial",
      editable: false,
      sorter: {
        compare: (a, b) => a.email_comercial.localeCompare(b.email_comercial),
      },
      required: true,
      inputType: "email",
      width: "17.53vw",
      align: "center",
    },
    {
      title: "Polo Comercial",
      dataIndex: "polo_comercial",
      editable: true,
      sorter: {
        compare: (a, b) => a.polo_comercial.localeCompare(b.polo_comercial),
      },
      inputType: "select",
      selectOptions: polosComerciais,
      required: true,
      align: "center",
      width: "23vw",
    },
    {
      title: "Usuário",
      dataIndex: "usuario",
      editable: false,
      sorter: { compare: (a, b) => a.usuario.localeCompare(b.usuario) },
      disabled: true,
      align: "center",
      width: "17vw",
    },
  ];

  function getDownloadData() {
    return data.map((dados) => [
      dados.email_comercial,
      dados.polo_comercial,
      dados.usuario,
    ]);
  }

  const initialStates = {
    usuario: userName.email,
  };

  return (
    <>
      {loaded ? (
        <Box
          downloadLabels={downloadLabels}
          downloadData={getDownloadData()}
          hasDownload
        >
          <EditableTable
            title={"Comerciais"}
            search
            createDefaultValues={initialStates}
            columns={colunas}
            data={data}
            createRequest={createRequest}
            editRequest={editRequest}
            deleteRequest={deleteRequest}
            createButtonTitle={"Cadastrar Agente"}
            defaultPageSize={100}
          />
        </Box>
      ) : (
        <LoadingOutlined spin />
      )}
    </>
  );
};

export default Comerciais;
