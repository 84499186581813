import React, { useEffect, useState } from 'react';
import { Alert, Button, Select } from '@stonelog/stonelog-design-system';
import { Switch } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { angelsSelector } from '../../../../../../../../../features/senninha/angelsSlice';
import { pinsSelector } from '../../../../../../../../../features/senninha/pinsSlice';
import { resetSelectedPreDeliveryPins, resetSelectedRelocatePins, resetUnassociatedPinAnyData } from '../../../../../../../../../features/senninha/routesManagementSlice';
import { getTrackingDeliveries, trackingDeliveriesSelector } from '../../../../../../../../../features/senninha/trackingDeliveriesSlice';
import { hubsSelector } from '../../../../../../../../../features/senninha/hubsSlice';

import * as S from "./styles";

const SwapRoutes = ({
    setNewWorkFlow,
    setRoutes
}) => {
    const [selectedAngel1, setSelectedAngel1] = useState()
    const [selectedAngel2, setSelectedAngel2] = useState()
    const [shouldConsiderCampaign, setShouldConsiderCampaign] = useState(false)
    const [angelBusy, setAngelBusy] = useState()

    const dispatch = useDispatch()

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const { angels, status } = useSelector(angelsSelector);

    const { pins } = useSelector(pinsSelector);

    const getAngelsOptions = () => angels?.map(({ nome }) => ({
        value: nome,
        label: nome,
    }));

    const { deliveries } = useSelector(trackingDeliveriesSelector)

    const handleSaveClick = () => {
        setRoutes((p) => [...p, {
            type: 'exchange',
            angel1: angels?.find(({ nome }) => nome === selectedAngel1),
            pins1: pins.filter((item) =>
                item.angel === selectedAngel1
                && (shouldConsiderCampaign ?
                    item.servico?.toLowerCase() === 'campanha'
                    : item.servico?.toLowerCase() !== 'campanha'
                )
            ),
            angel2: angels?.find(({ nome }) => nome === selectedAngel2),
            pins2: pins.filter((item) =>
                item.angel === selectedAngel2
                && (shouldConsiderCampaign ?
                    item.servico?.toLowerCase() === 'campanha'
                    : item.servico?.toLowerCase() !== 'campanha'
                )
            ),
            polo: selectedHubInfos.children,
            campanha: shouldConsiderCampaign,
        }])
        setNewWorkFlow(false)
        dispatch(resetSelectedRelocatePins());
        dispatch(resetSelectedPreDeliveryPins());
    }

    useEffect(() => {
        dispatch(getTrackingDeliveries(selectedHubInfos.children))
    }, [])

    useEffect(() => {
        const angel1 = angels?.find((item) => item.nome === selectedAngel1)
        const angel2 = angels?.find((item) => item.nome === selectedAngel2)
        const busyOne = angels?.find(({ email }) =>
            [...new Set(deliveries?.map(({ angel }) => angel))]
                .find((email) =>
                    email === angel1?.email || email === angel2?.email
                ) === email
        )

        if (busyOne) setAngelBusy(busyOne)
        else setAngelBusy()
    }, [selectedAngel1, selectedAngel2, deliveries])

    return (
        <S.SwapContainer>
            <S.SwapSelects>
                <Select
                    label='Angel 1'
                    placeholder='Selecione um Angel'
                    options={getAngelsOptions().filter(({ value }) => value !== selectedAngel2)}
                    value={selectedAngel1}
                    onChange={(v) => setSelectedAngel1(v)}
                    loading={status === 'pending'}
                    size="small"
                />
                <Select
                    label='Angel 2'
                    placeholder='Selecione um Angel'
                    options={getAngelsOptions().filter(({ value }) => value !== selectedAngel1)}
                    value={selectedAngel2}
                    onChange={(v) => setSelectedAngel2(v)}
                    loading={status === 'pending'}
                    size="small"
                />
            </S.SwapSelects>
            <S.SwapCampaign>
                <div>
                    <span>
                        OS de campanha
                    </span>
                    <p>
                        Incluir OS de Campanha na troca de rotas.
                    </p>
                </div>
                <Switch
                    value={shouldConsiderCampaign}
                    onChange={(v) => setShouldConsiderCampaign(v)}
                />
            </S.SwapCampaign>

            {angelBusy &&
                <Alert
                    description={`O angel %b%${angelBusy.nome} (${angelBusy.email})%b% possui atendimento Any em andamento, portanto, os pins Any %b%não serão trocados%b%.`}
                    type="warning"
                />
            }

            <S.Footer>
                <Button
                    size='small'
                    onClick={() => {
                        setNewWorkFlow(false)
                        dispatch(resetSelectedRelocatePins())
                        dispatch(resetSelectedPreDeliveryPins());
                        dispatch(resetUnassociatedPinAnyData());
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    size='small'
                    type='primary'
                    onClick={handleSaveClick}
                    disabled={!selectedAngel1 || !selectedAngel2 || angelBusy}
                >
                    Salvar
                </Button>
            </S.Footer>
        </S.SwapContainer>
    )
};

export default SwapRoutes;
