import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'zipCodeAddressInformation';
const ZIP_CODE_ADDRESS_INFORMATION = 'zipCodeAddressInformation/zipCodeAddressInformation';

const initialState = {
    zipCodeAddressInformationData: {},
    zipCodeAddressInformationStatus: 'idle',
    zipCodeAddressInformationError: 'idle',
};

export const getZipCodeAddressInformation = createAsyncThunk(ZIP_CODE_ADDRESS_INFORMATION, async (zipcode, { rejectWithValue }) => {
    try {

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/busca_endereco_pelo_cep/${zipcode}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const zipCodeAddressInformationSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        clearZipCodeAddressInformation: (state) => {
            state.zipCodeAddressInformationData = {};
            state.zipCodeAddressInformationStatus = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getZipCodeAddressInformation.pending, (state) => {
                state.zipCodeAddressInformationStatus = 'pending';
                state.zipCodeAddressInformationError = null;
            })
            .addCase(getZipCodeAddressInformation.fulfilled, (state, action) => {
                state.zipCodeAddressInformationData = action.payload;
                state.zipCodeAddressInformationStatus = 'fulfilled';
            })
            .addCase(getZipCodeAddressInformation.rejected, (state, action) => {
                state.zipCodeAddressInformationError = action.payload;
                state.zipCodeAddressInformationStatus = 'rejected';
            })
    },
});

export const { clearZipCodeAddressInformation } = zipCodeAddressInformationSlice.actions

export const zipCodeAddressInformationSelector = (state) => state.zipCodeAddressInformationSlice;

export default zipCodeAddressInformationSlice.reducer;
