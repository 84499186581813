import React, { useState, useEffect, useRef } from "react";
import {
    DirectionsRenderer,
    GoogleMap,
    Marker,
} from "@react-google-maps/api";
import { personIconSvg } from "./../../../../../../../assets/icons/DeliveryTrackingIcon";
import { startIconSvg } from "./../../../../../../../assets/icons/DeliveryStartTrackingIcon";
import { endIconSvg } from "./../../../../../../../assets/icons/DeliveryEndTrackingIcon";
import { useDispatch } from "react-redux";
import { addTrackingRoute, trackingRoutesSelector } from "../../../../../../../features/senninha/trackingRoutesSlice";
import { useSelector } from "react-redux";

import LogisticService from "../../../../../../../consumingApi/services/LogisticService";


const containerStyle = {
    width: "100%",
    height: "209px",
    marginBottom: "16px"
};


const DetailsDeliveryContentTracking = ({ isOpen, trackingCode }) => {
    const { routesPolylines } = useSelector(trackingRoutesSelector)

    const [state, setState] = useState();
    const trackingPullingRef = useRef(null); // Armazena o intervalo de pulling

    const directionsData = routesPolylines.find((item) => item.trackingCode === trackingCode)
    const originCoords = { lat: state?.data?.pickupAddress?.latitude, lng: state?.data?.pickupAddress?.longitude }
    const destinationCoords = { lat: state?.data?.deliveryAddress?.latitude, lng: state?.data?.pickupAddress?.longitude }
    
    const dispatch = useDispatch();

    const loadTrackingDetails = async () => {
        const tracking = await LogisticService.getTrackingDelivery(trackingCode);
        setState(tracking);
    };

    useEffect(() => {
        loadTrackingDetails();

        return () => {
            console.log('[DeliveryUpdate] [Pulling] End ...');
            if (trackingPullingRef.current) {
                clearInterval(trackingPullingRef.current);
                trackingPullingRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (isOpen === true && state?.data?.courier) {
            if (!trackingPullingRef.current) {
                console.log('Iniciando pulling para tracking');
                trackingPullingRef.current = setInterval(() => {
                    console.log('[DeliveryUpdate] [Pulling] Get Tracking ');
                    loadTrackingDetails();
                }, 10000);
            }
        } else {
            if (trackingPullingRef.current) {
                clearInterval(trackingPullingRef.current);
                trackingPullingRef.current = null;
            }
        }
    }, [isOpen, state?.data?.status]);

    useEffect(() => {
        if (!state || directionsData) return;

        const directionsService = new google.maps.DirectionsService();
    
        directionsService.route(
          {
            origin: originCoords,
            destination: destinationCoords,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              dispatch(
                addTrackingRoute({
                  trackingCode,
                  directions: result,
                })
              );
            } else {
              console.error("Erro ao obter rota:", status);
            }
          }
        );
      }, [state, directionsData]);

    return (isOpen && state?.data?.courier) && (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: state.data.courier.location.latitude, lng: state.data.courier.location.longitude }}
                zoom={15}
                clickableIcons={false}
                on
                options={{
                    disableDefaultUI: true,
                    zoomControl: false,
                    keyboardShortcuts: false,
                    panControlOptions: false
                }}
            >   
                {directionsData?.directions && <DirectionsRenderer directions={directionsData?.directions} options={{
                    suppressMarkers: true
                }} />}

                <Marker
                    key={2}
                    position={originCoords}
                    icon={{
                        url: `data:image/svg+xml;base64,${btoa(startIconSvg)}`,
                        scaledSize: new window.google.maps.Size(27, 27),
                    }}
                />
                <Marker
                    key={3}
                    position={destinationCoords}
                    icon={{
                        url: `data:image/svg+xml;base64,${btoa(endIconSvg)}`,
                        scaledSize: new window.google.maps.Size(27, 27),
                    }}
                />
                <Marker
                    key={1}
                    position={{ lat: state.data.courier.location.latitude, lng: state.data.courier.location.longitude }}
                    icon={{
                        url: `data:image/svg+xml;base64,${btoa(personIconSvg)}`,
                        scaledSize: new window.google.maps.Size(27, 27),
                    }}
                >
                </Marker>
            </GoogleMap>
        </>
    );
};

export default DetailsDeliveryContentTracking;