import React, { useState } from "react";
import { Form, Switch } from "antd";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { PageLayout } from "../../../../Layout/PageLayout";
import Page from "@components/Page";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import Dragger from "@components/CustomDragger";

import DefaultInput from "@components/DefaultInput";
import SubmitButton from "@components/SubmitButton";
import InfoTable from "@components/InfoTable";
import CustomAlert from "@components/CustomAlert";

import LogisticService from "@LogisticService";

import { Download, Exemplo, Span, TextError, TextSuccess } from "../styles";
import Table from "./table";

export default function InputLiga(props) {
  const [successfully, setSuccessfully] = useState("wait");
  const [successfullyMultiple, setSuccessfullyMultiple] = useState("wait");

  const [dataDragger, setDataDragger] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);

  const [alertOptions, setAlertOptions] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const formRef = React.createRef();
  const multipleFormRef = React.createRef();
  const [form] = Form.useForm();
  const [multipleForm] = Form.useForm();

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postArvoreLiga(data)
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const adicionarResultados = (status, formObj) => {
    let result;
    const date = new Date();

    if (status === 200) {
      result = () => {
        return (
          <TextSuccess>
            <CheckCircleOutlined /> Sim
          </TextSuccess>
        );
      };
    } else {
      result = () => {
        return (
          <TextError>
            <StopOutlined /> Nop
          </TextError>
        );
      };
    }

    formObj = {
      inserido: result(),
      horario: date.getHours() + ":" + date.getMinutes(),
      ...formObj,
      ativo: formObj.ativo ? "Sim" : "Não",
    };
    setDadosTabela((oldState) => [formObj, ...oldState]);
  };

  const onFinishMultipleCases = () => {
    dataDragger.forEach(async (formObj) => {
      const status = await postForm(formObj);
      adicionarResultados(status, formObj);

      if (status === 200) {
        setSuccessfullyMultiple("ok");
        form.resetFields();
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      } else {
        setSuccessfullyMultiple("erro");
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      }
    });
    multipleForm.resetFields();
  };

  const handleOnUpload = (dataFileDragger) => {
    setDataDragger(dataFileDragger);
  };

  const onFinishInsert = async (data) => {
    const formObj = {
      ...data,
      ativo: data.ativo ? true : false,
    };
    const status = await postForm(formObj);
    adicionarResultados(status, formObj);

    if (status === 200) {
      setSuccessfully("ok");
      form.resetFields();
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };

  const colunasTabela = [
    { label: "Inserido?", key: "inserido" },
    { label: "Horário", key: "horario" },
    { label: "Tema", key: "tema" },
    { label: "Categoria", key: "categoria" },
    { label: "Assunto", key: "assunto" },
    { label: "Fila", key: "fila" },
    { label: "Ativo", key: "ativo" },
  ];

  const tableData = {
    data: dadosTabela,
    columns: colunasTabela,
  };

  const rows = [
    <Row>
      <Form.Item
        name="tema"
        label="Tema"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Informe o Tema!" }]}
      >
        <DefaultInput />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="categoria" label="Categoria" labelCol={{ span: 24 }}>
        <DefaultInput />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="assunto" label="Assunto" labelCol={{ span: 24 }}>
        <DefaultInput />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="fila" label="Fila" labelCol={{ span: 24 }}>
        <DefaultInput />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="ativo" label="Está Ativo?" labelCol={{ span: 24 }}>
        <Switch defaultChecked={true} />
      </Form.Item>
    </Row>,
  ];

  return (
    <PageLayout>
      <Box title="Árvore da LIGA">
        <Row horizontalAlign={"space-between"}>
          <Container
            grid={1.5 / 3}
            title={"Inserção de Árvore"}
            padding={"16px"}
            rounded={true}
          >
            <Form
              onFinish={onFinishInsert}
              form={form}
              ref={formRef}
              initialValues={{ ativo: true }}
            >
              {rows}
              <Row horizontalAlign={"center"}>
                <SubmitButton
                  verifyer={successfully}
                  textSuccess="Inserido Com Sucesso! (^◡^ )"
                  textError="Falha Ao Inserir! ( ˘︹˘ )"
                >
                  inserir
                </SubmitButton>
              </Row>
            </Form>
          </Container>
          <Container grid={2 / 3} rounded={true}>
            <Row>
              <Table setAlertOptions={setAlertOptions} successfully={successfully}/>
            </Row>
          </Container>
        </Row>
        <Row>
          <Container
            grid={1 / 3}
            title={"Inserção de Árvore"}
            padding={"16px"}
            rounded={true}
          >
            <Form
              onFinish={onFinishMultipleCases}
              form={multipleForm}
              ref={multipleFormRef}
            >
              <Row>
                <Form.Item
                  name="dragger"
                  rules={[
                    { required: true, message: "Nenhum Arquivo Selecionado!" },
                  ]}
                >
                  <Dragger
                    name="dragger"
                    title="Importar Múltiplos TCAs Para a Árvore"
                    text="Selecione ou Arraste um Arquivo XLSX/CSV Até Esta Área"
                    handleOnUpload={handleOnUpload}
                  />
                </Form.Item>
              </Row>
              <Row horizontalAlign={"center"}>
                <SubmitButton
                  verifyer={successfullyMultiple}
                  textSuccess="Registrado Com Sucesso! (✿◠‿◠)"
                  textError="Falha Ao Registrar! ( ˘︹˘ )"
                >
                  importar
                </SubmitButton>
              </Row>
              <Exemplo>
                Dúvidas? Clique{" "}
                <Download
                  href={"/dist/Exemplo Arvore Liga.xlsx"}
                  target="_blank"
                >
                  aqui
                </Download>{" "}
                para baixar um modelo de exemplo.
              </Exemplo>
            </Form>
          </Container>
          <Container grid={2 / 3} rounded={true}>
            <Row>
              <Span style={{ padding: "16px" }}>
                Visualização de TCAs Importados
              </Span>
            </Row>
            <Row>
              <InfoTable
                data={tableData}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              />
            </Row>
          </Container>
        </Row>
        <CustomAlert
          message={alertOptions.message}
          type={alertOptions.type}
          visible={alertOptions.visible}
          closable
        />
      </Box>
    </PageLayout>
  );
}
