import React, { useEffect, useMemo, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Marker from "./components/Marker";
import appConfig from "@appConfig";
import Drawers from "./components/Drawers";
import { getAllPinsData, pinsSelector } from "../../../../features/senninha/pinsSlice";
import { hubsSelector } from "../../../../features/senninha/hubsSlice";
import { oldSenninhaSelector } from "../../../../features/senninha/oldSenninhaSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedPreDeliveryPins, resetSelectedRelocatePins, setRouteManagementOption } from "../../../../features/senninha/routesManagementSlice";
import { Dialog, Loading } from "@stonelog/stonelog-design-system";
import { getOptions } from "../../../../features/optionsSlice";
import { resetDeliverysState } from "../../../../features/senninha/trackingDeliveriesSlice";
import { getAlertUnproductivityJustificationTable, setResetAssessUnproductivityJustificationStatus, setResetJustificationStatus } from "../../../../features/senninha/GestaoEquipe/unproductivityJustificationSlice";
import OldSenninha from "../OldSenninha";
import Poligonos from "../OldSenninha/Poligono";

import * as S from "./styles";


const Map = () => {
    // configs que referenciam o mapa 
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [isMarkerLibraryLoaded, setIsMarkerLibraryLoaded] = useState(false);
    const GMAPS_API_KEY = appConfig.mapsKey;

    const dispatch = useDispatch();

    const {
        center,
        zoom,
        pinsStatus,
        filteredPins
    } = useSelector(pinsSelector);

    const {
        selectedHubInfos,
        selectedDistrictInfos
    } = useSelector(hubsSelector);

    const {
        oldSenninhaWorkFlow
    } = useSelector(oldSenninhaSelector);

    useEffect(() => {
        dispatch(getAllPinsData({
            provider: selectedHubInfos?.children // children é o valor do hub/polo
        }));
        dispatch(resetDeliverysState())
        dispatch(getOptions())
        dispatch(resetSelectedRelocatePins())
        dispatch(resetSelectedPreDeliveryPins());
        dispatch(setRouteManagementOption('realocar'))
    }, []);

    useEffect(() => {
        dispatch(getAlertUnproductivityJustificationTable({
          polo: selectedHubInfos?.children,
          distrito: selectedDistrictInfos?.children,
        }))
        dispatch(setResetJustificationStatus())
        dispatch(setResetAssessUnproductivityJustificationStatus())
      }, []);

    useEffect(() => {
        window.google.maps.importLibrary("marker").then(() => setIsMarkerLibraryLoaded(true));
    }, []);

    useEffect(() => {
        if (ref.current !== null && map === undefined && isMarkerLibraryLoaded) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    mapId: `${Math.random()}`,
                    clickableIcons: false,
                    disableDefaultUI: true,
                    center: { lat: -22.8996166, lng: -43.1978316 },
                    zoom: 12,
                    zoomControl: true
                })
            );
        }
        if (map !== undefined) {
            map.setCenter(center);
            map.setZoom(zoom);
        }
    }, [map, isMarkerLibraryLoaded, center, zoom]);

    return (
        <>
            {pinsStatus === 'pending' && (
                <S.ScreenMask>
                    <Dialog.Root
                        closable={false}
                        open={true}
                    >
                        <Dialog.Header />
                        <Dialog.ContentHeader
                            ilustration={<Loading />}
                            description={
                                <S.TextContent>
                                    Segura aí! Estamos carregando os pins para que você possa utilizar o Senninha.
                                </S.TextContent>
                            }
                        />
                        <Dialog.Footer />
                    </Dialog.Root>
                </S.ScreenMask>
            )}
            <Wrapper
                apiKey={GMAPS_API_KEY}
                libraries={['drawing']}
            >
                <Drawers />
                <OldSenninha />

                <S.MapContainer ref={ref}>
                    {map && (
                        <Poligonos
                            map={map}
                        />
                    )}
                    {(pinsStatus === 'fulfilled' && isMarkerLibraryLoaded && filteredPins?.length && oldSenninhaWorkFlow !== 'poligonos') ? (
                        filteredPins.map((dataPin, idx) => {
                            return (
                                <Marker
                                    key={idx}
                                    idx={idx}
                                    map={map}
                                    position={{ lat: dataPin.lat, lng: dataPin.lng }}
                                    dataPin={dataPin}
                                />
                            )
                        })
                    ) : <></>
                }
                </S.MapContainer>
            </Wrapper>
        </>
    );
};

export default Map;
