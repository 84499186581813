import * as Yup from 'yup';

export const schema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .matches(/@stone.com.br$/, 'Necessário ser email stone')
        .required('Campo obrigatório')
    ,
    pessoaResponsavel: Yup.string()
        .required('Campo obrigatório')
    ,
    contato: Yup.string()
        .required('Campo obrigatório')
    ,
    cep: Yup.string()
        .trim()
        .min(8, 'O cep deve ter 8 dígitos')
        .max(8, 'O cep deve ter 8 dígitos')
        .required('Informe o seu código postal no formato: 20221310')
    ,
    endereco: Yup.string()
        .required('Campo obrigatório'),
    numeroEndereco: Yup.string()
        .required('Campo obrigatório'),
    complemento: Yup.string()
    ,
    bairro: Yup.string()
        .required('Campo obrigatório')
    ,
    cidade: Yup.string()
        .min(2, "O campo estado deve ser completo: Rio de Janeiro")
        .required('Campo obrigatório')
    ,
    uf: Yup.string()
        .required('Campo obrigatório')
    ,
});