import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'teamManagementProviderInformation';
const GET_PROVIDER_INFORMATION = 'teamManagementProviderInformation/getProviderInformation';
const POST_PROVIDER_INFORMATION = 'teamManagementProviderInformation/postProviderInformation';

const initialState = {
    getProviderInformationData: [],
    getProviderInformationStatus: 'idle',
    getProviderInformationDataError: [],
    getProviderInformationStatusError: 'idle',

    postProviderInformationData: [],
    postProviderInformationStatus: 'idle',
    postProviderInformationDataError: [],
    postProviderInformationStatusError: 'idle'
};

export const getProviderInformation = createAsyncThunk(GET_PROVIDER_INFORMATION, async ({ polo }, { rejectWithValue }) => {
    try {

        const params = new URLSearchParams();

        params.append('polo', polo);

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/gestao_equipe/endereco_polo/informacoes?${params.toString()}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const postProviderInformation = createAsyncThunk(POST_PROVIDER_INFORMATION, async (data, { rejectWithValue }) => {
    try {

        const res = await LogisticService.postWithAuth(`${BASE_URL}/gestao_equipe/endereco_polo/informacoes`, data);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const providerInformationsSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        setResetPostProviderInformationStatus: (state) => {
            state.postProviderInformationStatus = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProviderInformation.pending, (state) => {
                state.getProviderInformationStatus = 'pending';
                state.getProviderInformationStatusError = null;
            })
            .addCase(getProviderInformation.fulfilled, (state, action) => {
                state.getProviderInformationData = action.payload;
                state.getProviderInformationStatus = 'fulfilled';
            })
            .addCase(getProviderInformation.rejected, (state, action) => {
                state.getProviderInformationStatusError = action.payload;
                state.getProviderInformationStatus = 'rejected';
            })

            .addCase(postProviderInformation.pending, (state) => {
                state.postProviderInformationStatus = 'pending';
                state.postProviderInformationStatusError = null;
            })
            .addCase(postProviderInformation.fulfilled, (state, action) => {
                state.postProviderInformationData = action.payload;
                state.postProviderInformationStatus = 'fulfilled';
            })
            .addCase(postProviderInformation.rejected, (state, action) => {
                state.postProviderInformationDataError = action.payload;
                state.postProviderInformationStatus = 'rejected';
            })
    },
});

export const { setResetPostProviderInformationStatus } = providerInformationsSlice.actions

export const providerInformationsSelector = (state) => state.providerInformationsSlice;

export default providerInformationsSlice.reducer;
