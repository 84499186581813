import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageLayout } from "../../../../../Layout/PageLayout";
import { useHistory } from "react-router-dom";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";
import { getProviderInformation, providerInformationsSelector } from "../../../../../features/senninha/GestaoEquipe/providerInformationsSlice";
import { getEmployeesInfosLogistic } from "../../../../../features/senninha/employeesInfosLogistic";
import Breadcrumbs from "../../../../../components/Breadcumbs";
import PageHeader from "@components/PageHeader";
import InfosProviderEditing from "./InfosProviderEditing";
import { capitalizeFirstWord } from "../../../../../utils/formatter";

import * as S from "./styles";


const TeamManagement = () => {

    const [openProviderEditDrawer, setOpenProviderEditDrawer] = useState(false)

    const dispatch = useDispatch();

    const history = useHistory();

    const {
        postProviderInformationStatus
    } = useSelector(providerInformationsSelector)

    const {
        selectedDistrictInfos,
        selectedRegionInfos,
        selectedHubInfos
    } = useSelector(hubsSelector);

    useEffect(() => {
        dispatch(getProviderInformation({ polo: selectedHubInfos.children }));
    }, []);

    useEffect(() => {
        if (postProviderInformationStatus === 'fulfilled') {
            dispatch(getProviderInformation({ polo: selectedHubInfos.children }));
            setOpenProviderEditDrawer(false)
        }
    }, [postProviderInformationStatus]);

    useEffect(() => {
        dispatch(getEmployeesInfosLogistic())
      }, [])

    const {
        getProviderInformationData,
    } = useSelector(providerInformationsSelector)

    const optionsTeamButton = [
        {
            icon: "location",
            title: "Controle de Roteirização",
            subtitle: "Gerencie colaboradores para roteirização e acompanhe os registros diários feitos no Green App.",
            onClick: () => {
                // history.push({
                //     pathname: "/lmp/senninha/gestao-de-equipe/controle-de-roteirizacao",
                // })
                console.log("Não implementado")
            },
            suffixIcon:
                <GingaIcon
                    name='chevron-right'
                    color="rgba(1, 30, 50, 0.24)"
                    size={24}
                />,
            disabled: true
        },
        {
            icon: "avatar-delete",
            title: "Justificativa de Improdutividade",
            subtitle: "Acompanhe a produtividade dos colaboradores e justifique absenteísmos diariamente.",
            onClick: () => {
                history.push({
                    pathname: "/lmp/senninha/gestao-de-equipe/justificativa-de-improdutividade",
                })
            },
            suffixIcon:
                <GingaIcon
                    name='chevron-right'
                    color="rgba(1, 30, 50, 0.24)"
                    size={24}
                />,
            disabled: false
        },
        {
            icon: "shop",
            title: `${capitalizeFirstWord(selectedHubInfos?.children) || selectedHubInfos?.children }`,
            subtitle: `${getProviderInformationData?.endereco}, ${getProviderInformationData?.numero_endereco} 
            ${getProviderInformationData?.complemento && ('- ' + getProviderInformationData?.complemento)}, ${getProviderInformationData?.bairro} - ${getProviderInformationData?.uf},
             ${getProviderInformationData?.cep}`,
            onClick: () => {
                setOpenProviderEditDrawer(true)
            },
            suffixIcon:
                <S.EditPoloContainer>
                    <GingaIcon
                        name='edit'
                        color="rgba(48, 55, 66, 1)"
                        size={20}
                    />
                </S.EditPoloContainer>,
            disabled: false
        },
    ];

    return (
        <PageLayout
            titlePage={" |  Senninha"}
            showHamburguerIcon={true}
        >
            <S.AllPageTeam>
                <Breadcrumbs
                    onPathClickConfig={{
                        pathSnippet: 'senninha',
                        data: {
                            region: selectedRegionInfos.children,
                            district: selectedDistrictInfos.children,
                            pole: selectedHubInfos.children,
                        }
                    }}
                />
                <PageHeader
                    title="Gestão de Equipe"
                    onGoBack={() => {
                        history.push({
                            pathname: "/senninha",
                            state: {
                                region: selectedRegionInfos.children,
                                district: selectedDistrictInfos.children,
                                pole: selectedHubInfos.children,
                            },
                        })
                    }}
                />
                <S.ContainerPageTeam>

                    <InfosProviderEditing
                        openProviderEditDrawer={openProviderEditDrawer}
                        setOpenProviderEditDrawer={setOpenProviderEditDrawer}
                    />

                    {optionsTeamButton.map((optionsButton, idx) => {
                        return (
                            <S.ContentButtonTeam
                                key={idx}
                                onClick={optionsButton.onClick}
                                disabled={optionsButton.disabled}
                            >
                                <S.LeftContentButtonTeam>
                                    <S.IconButtonTeam>
                                        <GingaIcon
                                            name={optionsButton.icon}
                                            color="rgba(0, 113, 63, 0.87)"
                                            size={24}
                                        />
                                    </S.IconButtonTeam>
                                    <S.TextButtonTeam>
                                        <span>
                                            {optionsButton.title}
                                        </span>
                                        <p>
                                            {optionsButton.subtitle}
                                        </p>
                                    </S.TextButtonTeam>
                                </S.LeftContentButtonTeam>
                                <S.RightContentButtonTeam>
                                    {optionsButton.suffixIcon}
                                </S.RightContentButtonTeam>
                            </S.ContentButtonTeam>
                        )
                    })}
                </S.ContainerPageTeam>
            </S.AllPageTeam>
        </PageLayout>
    );
};

export default TeamManagement;
