export const oplFromTo = (label) => {
  if (label.toLowerCase() === "loggilocal") return "Loggi";
  return label;
};

export const uninstallServicesTypes = [
  "AJUSTE GA",
  "CAÇA POS",
  "CAMPANHA",
  "CANCELAMENTO MEI",
  "DESCREDENCIAMENTO",
  "DESINSTALAÇÃO",
  "DESINSTALAÇÃO MEI",
  "DESINSTALAÇÃO PINPAD",
  "HIJACK",
  "RECREDENCIAMENTO",
  "REVERSÃO TON",
  "TROCA ELAVON",
  "X - CAMPANHA"
]