import styled from "styled-components";


export const Header = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  span {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }


    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const Button = styled.span`
  padding: 10px 16px;
  background-color: ${(props) => props.isActive ? '#00A868' : '#767F8D1A'};
  color: ${(props) => props.isActive ? '#fff' : '#303742'};
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  letter-spacing: -0.01em;
`;

export const Tags = styled.div`
  padding: 4px 0 12px 0;
  margin: 0;
  border-bottom: 1px solid #3037421A;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Radios = styled.div`
  margin: 0;
  padding: 12px 0;
  border-bottom: 1px solid #3037421A;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-radio-group {
    /* justify-content: space-between; */
    display: flex;
  }
`;

export const BalanceArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
  
  p {
    color: #A6AEBA;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.1px; 
  }
`;

export const FormArea = styled.div`
  height: calc(100% - 296px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ClientArea = styled.div`
  padding: 8px 0 20px 0;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
`;

export const ClientInfoBlock = styled.div`  
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #3037421A;
  padding: 8px 0;
  gap: 8px;
`;

export const ClientFormBlock = styled(ClientInfoBlock)`
  padding: 16px 0 8px 0;
  border: none;
  gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 15px;
  line-height: 21.75px;
  letter-spacing: -0.01em;
  gap: 8px;

  > span:first-child {
    display: flex;
    align-items: center;
    color: #767F8D;

    svg {
      margin-left: 4px;
    }
  }

  > span:last-child {
    color: #303742;
    font-weight: 600;
    text-align: end;
  }
`;

export const UnninstallCollectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  > div:first-child {
    letter-spacing: -0.01em;

    > div:first-child {
      color: #303742;
      font-size: 15px;
      line-height: 21.75px;
    }

    > div:last-child {
      color: #767F8D;
      font-size: 13px;
      line-height: 18.85px;
    }
  }

  .ant-switch-handle {
    width: 24px;
    height: 24px;
    
    &::before {
      border-radius: 12px !important;
    }
  }

  .ant-switch {
    height: 28px !important;
    width: 48px !important;

    .ant-switch-handle {
      left: 3px !important;
    }
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 3px) !important;
  }
`;

export const SupplyFormSelects = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GroupedServiceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  > div:first-child {
    letter-spacing: -0.01em;

    > div:first-child {
      color: #303742;
      font-size: 15px;
      line-height: 21.75px;
    }
    
    > div:last-child {
      color: #767F8D;
      font-size: 13px;
      line-height: 18.85px;
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const MultiFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

// TODO: temp
export const Div = styled.div`
  border: 1px solid red;
  width: 300px;
  max-height: 85px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  white-space: pre-wrap; 
  word-wrap: break-word;

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.isActive ? '#00A8681A' : '#767F8D1A'};
  padding: 8px;
  border-radius: 8px;
  margin-top: 12px;
`;

export const CheckboxArea = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxText = styled.div`
  color: #303742;
  font-size: 15px;
  line-height: 21.75px;
  letter-spacing: -0.01em;
  margin-left: 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
  padding: 12px 0;
  border-bottom: 1px solid #3037421A;
`;

export const HeaderTitle = styled.div`
  font-size: 15px;
  line-height: 21.75px;
  letter-spacing: -0.01em;
  color: #303742;
`;

export const HeaderSubTitle = styled.div`
  font-size: 13px;
  line-height: 18.85px;
  letter-spacing: -0.01em;
  color: #767F8D;
`;

export const CheckBoxAllArea = styled.div`
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TagsContainer = styled.div`
  padding: 12px 0;
  border-top: 1px solid #767f8d1a;
  border-bottom: 1px solid #767f8d1a;
`;

export const Divider = styled.div`
  margin: 12px 0;
`;

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TagsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 18.85px;
  letter-spacing: -0.01em;
  color: #303742;
  margin-left: 8px;
`;

export const ResponseArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextResponse = styled.div`
  color: #303742;
  font-size: 15px;
  letter-spacing: -0.01em;
  line-height: 21.75px;
  margin-top: 8px;
`;
