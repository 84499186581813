import React, { useEffect, useRef, useState } from "react";
import LogisticService from "@LogisticService";
import { Tag, Form, Space, Input, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import EditableTable from "@components/EditableTable";
import Box from "@components/Box";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import FormItem from "antd/lib/form/FormItem";
import { Info } from "../../../../componentes/Ferramentas/styles";
import { useAuthContext } from "@stonelog/stonelog-auth-components";

import { InputWithTags } from "../styles";
import { Loading } from "@stonelog/stonelog-design-system";

const Seriais = ({ polo }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedPoloComercial, setSelectedPoloComercial] = useState(null);
  const [polosComerciais, setPolosComerciais] = useState([]);
  const [comerciaisDisponiveis, setComerciaisDisponiveis] = useState([]);
  const [atualizaEditabletable, setAtualizaEditabletable] = useState(false);
  const [atualizaComerciais, setAtualizaComerciais] = useState(false);
  const [downloadLabels, setDownloadLabels] = useState([
    "Serial",
    "Email Comercial",
    "Polo Comercial",
    "Usuário",
    "Dias com POS",
  ]);
  const [serialTags, setSerialTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const auth = useAuthContext();
  const userName = auth.getUser();

  useEffect(() => {
    const fetchPolosComerciais = async () => {
      const polos = await LogisticService.getPolosComerciais();
      try {
        if (polos !== undefined && polos.data !== null) {
          let polos_comerciais = [];
          polos.data.polos.map((polo) => {
            polos_comerciais.push({ id: polo, name: polo });
          });
          setPolosComerciais(polos_comerciais);
        }
      } catch (error) {
        message.error("Erro ao carregar polos comerciais.");
      }
    };

    fetchPolosComerciais();
  }, []);

  useEffect(() => {
    const fetchComerciaisDisponiveis = async () => {
      try {
        const comerciais = await LogisticService.getComerciaisDisponiveis(
          selectedPoloComercial
        );
        if (comerciais !== undefined && comerciais.data !== null) {
          let comerciais_disponiveis = [];
          comerciais.data.map((item) => {
            comerciais_disponiveis.push({ id: item, name: item });
          });
          setComerciaisDisponiveis(comerciais_disponiveis);
        }
      } catch (error) {
        message.error("Erro ao carregar comerciais disponíveis.");
      }
    };

    fetchComerciaisDisponiveis();
  }, [atualizaComerciais]);

  useEffect(() => {
    setLoaded(false);
    if (selectedPoloComercial) {
      LogisticService.getComerciaisDisponiveis(selectedPoloComercial).then(
        (comerciais) => {
          if (comerciais !== undefined && comerciais.data !== null) {
            let comerciais_disponiveis = [];
            comerciais.data.map((item) => {
              comerciais_disponiveis.push({ id: item, name: item });
            });
            setComerciaisDisponiveis(comerciais_disponiveis);
          }
          LogisticService.getConfiguracoesSeriais(selectedPoloComercial).then(
            (response) => {
              if (response !== undefined && response.data !== null) {
                setData(addDaysWithPos(response.data));
                setLoaded(true);
              }
            }
          );
        }
      );
    }
  }, [selectedPoloComercial]);

  const createRequest = async (data) => {
    const response = await LogisticService.postConfiguracoesSeriais(data);
    if (response.data) setAtualizaEditabletable(!atualizaEditabletable);

    setSerialTags([]);
    return { sucess: false, message: response.data };
  };

  const editRequest = async (data) => {
    const response = await LogisticService.postConfiguracoesSeriais(data);
    if (response.data) setAtualizaEditabletable(!atualizaEditabletable);
    if (response.data.sucesso) {
      return { success: true, message: response.data.sucesso };
    } else {
      return { success: false, message: response.data.erro.errors[0].message };
    }
  };

  const deleteRequest = async (data) => {
    const response = await LogisticService.postConfiguracoesSeriais({
      serial_number: [data.serial_number],
      logistic_provider: polo,
    });
    if (response.data) setAtualizaEditabletable(!atualizaEditabletable);
    if (response.data.sucesso) {
      return { success: true, message: response.data.sucesso };
    } else {
      return { success: false, message: response.data.erro.errors[0].message };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoaded(false);
        if (selectedPoloComercial) {
          const comerciais = await LogisticService.getComerciaisDisponiveis(
            selectedPoloComercial
          );
          if (comerciais) {
            let comerciais_disponiveis = [];
            comerciais.data.map((item) => {
              comerciais_disponiveis.push({ id: item, name: item });
            });
            setComerciaisDisponiveis(comerciais_disponiveis);
          }

          const response = await LogisticService.getConfiguracoesSeriais(
            selectedPoloComercial
          );
          if (response !== undefined && response.data !== null) {
            setData(addDaysWithPos(response.data));
            setLoaded(true);
          }
        }
      } catch (error) {
        message.error("Erro ao carregar comerciais disponíveis.");
      }
    };

    fetchData();
  }, [atualizaEditabletable]);

  const colunas = [
    {
      title: "Serial",
      dataIndex: "serial_number",
      editable: false,
      sorter: {
        compare: (a, b) => a.serial_number.localeCompare(b.serial_number),
      },
      required: true,
      width: "12vw",
      align: "center",
    },
    {
      title: "Email Comercial",
      dataIndex: "agent_email",
      editable: false,
      inputType: "select",
      selectOptions: comerciaisDisponiveis,
      sorter: { compare: (a, b) => a.agent_email.localeCompare(b.agent_email) },
      required: true,
      width: "12vw",
      align: "center",
    },
    {
      title: "Polo Comercial",
      dataIndex: "comercial_provider",
      editable: false,
      sorter: {
        compare: (a, b) =>
          a.comercial_provider.localeCompare(b.comercial_provider),
      },
      disabled: true,
      inputType: "select",
      selectOptions: polosComerciais,
      required: true,
      align: "center",
      width: "12vw",
    },
    {
      title: "Usuário",
      dataIndex: "register_responsible",
      editable: false,
      sorter: {
        compare: (a, b) =>
          a.register_responsible.localeCompare(b.register_responsible),
      },
      disabled: true,
      align: "center",
      width: "12vw",
    },
    {
      title: "Dias com POS",
      dataIndex: "days_with_pos",
      editable: false,
      sorter: { compare: (a, b) => a.days_with_pos - b.days_with_pos },
      disabled: true,
      align: "center",
      width: "12vw",
    },
  ];

  const initialStates = {
    register_responsible: userName.email,
    comercial_provider: selectedPoloComercial,
  };

  const onChangePoloComercial = (value) => {
    setSelectedPoloComercial(value);
    setAtualizaEditabletable(!atualizaEditabletable);
  };

  const addDaysWithPos = (data) =>
    data.map((item) => ({
      ...item,
      days_with_pos: Math.ceil(
        Math.abs(new Date(Date.parse(item.dt_start)) - new Date()) / 86400000
      ),
    }));

  function getDownloadData() {
    return data.map((dados) => [
      dados.serial_number,
      dados.agent_email,
      dados.comercial_provider,
      dados.register_responsible,
      dados.days_with_pos,
    ]);
  }

  const handleClose = (removedTag, form) => {
    const newTags = serialTags.filter((tag) => tag !== removedTag);
    setSerialTags(newTags);
    form.setFieldsValue({ serial_number: newTags });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e, form) => {
    if (e.target.value && serialTags.indexOf(e.target.value) === -1) {
      const newSerialNumbers = [...serialTags, e.target.value];
      setSerialTags(newSerialNumbers);
      form.setFieldsValue({ serial_number: newSerialNumbers });
    }
    setInputValue("");
  };

  const customCreateForm = (form) => {
    const forMap = (tag) => {
      const tagElem = (
        <Tag
          style={{
            backgroundColor: "#rgb(0 255 40 / 32%)",
          }}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(tag, form);
          }}
        >
          {tag}
        </Tag>
      );
      return (
        <span
          key={tag}
          style={{
            display: "inline-block",
          }}
        >
          {tagElem}
        </span>
      );
    };

    const tagChild = serialTags.map(forMap);

    const Tags = () =>
      serialTags.length > 0 &&
      tagChild.map((item, i) => <div key={i}>{item}</div>);

    return (
      <Form form={form} layout={"vertical"}>
        <Space>
          <FormItem
            name={"comercial_provider"}
            label="Polo Comercial"
            rules={[{ required: true }]}
          >
            <Input disabled style={{ width: "23rem" }} />
          </FormItem>
          <FormItem
            name={"agent_email"}
            label="Email Comercial"
            rules={[{ required: true }]}
          >
            <SelectBox
              options={comerciaisDisponiveis}
              valueIsName
              width={"18rem"}
            />
          </FormItem>
          <FormItem name={"register_responsible"} />
        </Space>
        <FormItem
          name={"serial_number"}
          label="Serial"
          rules={[{ required: true }]}
        >
          {console.log(inputValue)}
          <InputWithTags
            value={inputValue}
            onChange={handleInputChange}
            onBlur={(e) => handleInputConfirm(e, form)}
            onPressEnter={(e) => handleInputConfirm(e, form)}
            prefix={<Tags />}
            isEmpty={!serialTags.length > 0}
          />
        </FormItem>
      </Form>
    );
  };

  return (
    <>
      {polosComerciais.length === 0 ? (
        <Loading />
      ) : (
        <>
          <Info>Selecione o Polo Comercial: </Info>
          <SelectBox
            options={polosComerciais}
            onChange={onChangePoloComercial}
            placeholder={"Selecione"}
            width={"417px"}
          />
          {selectedPoloComercial && (
            <Box
              width={"100%"}
              horizontalAlign={"center"}
              downloadLabels={downloadLabels}
              downloadData={getDownloadData()}
              hasDownload
            >
              {loaded ? (
                <EditableTable
                  title="Seriais"
                  search
                  createDefaultValues={initialStates}
                  columns={colunas}
                  data={data}
                  createRequest={createRequest}
                  deleteRequest={deleteRequest}
                  createButtonTitle={"Atrelar POS"}
                  defaultPageSize={10}
                  customizeButton={() =>
                    setAtualizaComerciais(!atualizaComerciais)
                  }
                  deleteText={`Gostaria de retornar com esta máquina para o estoque do polo logístico atual?`}
                  customCreateForm={customCreateForm}
                />
              ) : (
                <Row
                  width={"100%"}
                  horizontalAlign={"center"}
                  margin={"8px 0 0"}
                >
                  <LoadingOutlined spin />
                </Row>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Seriais;
